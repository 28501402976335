import { Component } from "react";
import App from "./App";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, errorInfo: error.message };
    }

    componentDidCatch(error, errorInfo) {
        console.error("ErrorBoundary caught an error", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <div>
                <h1>에러가 발생하였습니다.</h1>
                <p>에러가 발생했습니다. 새로고침 해주세요.</p>
                <p>{this.state.errorInfo}</p>
            </div>;
        }

        return this.props.children;
    }
}

function AppWrapper() {
    return (
        <ErrorBoundary >
            <App />
        </ErrorBoundary>
    );
}

export default AppWrapper;