import React, { useCallback } from "react";
import {
  Card,
  Table,
  Button,
  ButtonGroup,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { useUserStore } from "../../../../store/store";
import CouponInfoModal from "../../../../Components/modal/CouponInfoModal";
import { getAccessToken } from "../../../../api/auth";
import {
  getCouponDataByPageParam,
  putReceptCouponById,
} from "../../../../api/service";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  /* max-width: 480px; */
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const confirm = (message = null, onConfirm, onCancel) => {
  if (!onConfirm || typeof onConfirm !== "function") return;
  if (onCancel && typeof onCancel !== "function") return;
  const confirmAction = () => {
    if (window.confirm(message)) onConfirm();
    else onCancel();
  };
  return confirmAction;
};

function CouponGift() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [infoModal, setInfoModal] = React.useState(false);
  const [selectCoupon, setSelectCoupon] = React.useState({});
  const [permission, setPermission, user] = useUserStore((state) => [
    state.permission,
    state.setPermission,
    state.user,
  ]);

  const accessToken = getAccessToken();

  const fetchData = useCallback(
    async ({ pageParam = 1 }) => {
      const response = await getCouponDataByPageParam(pageParam);
      const data = response.data;
      // console.log("orderdata", data);
      return { ...data, results: data.results };
    },
    [accessToken]
  );

  const receptCoupon = async (couponId) => {
    try {
      const response = await putReceptCouponById(couponId);

      return response.data;
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
      throw error;
    }
  };

  const {
    status,
    data,
    error,
    isSuccess,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    refetch,
  } = useInfiniteQuery(
    ["UserCouponGiftList"],
    async ({ pageParam = 1 }) => {
      // console.log("pageParam", pageParam);
      const data = await fetchData({ pageParam });
      // console.log("data", data);
      return data;
    },
    {
      // getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.next) {
          const match = lastPage.next.match(/page=(\d+)/);
          if (match) {
            return match[1];
          }
        }
        return undefined;
      },
      // getPreviousPageParam: (firstPage) => undefined,
    }
  );

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 d-flex flex-wrap justify-content-center gap-4">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          ) : null}

          {data && isSuccess ? (
            <>
              {data?.pages.map((page, i) => (
                <React.Fragment key={i}>
                  {page?.results
                    .filter(
                      (items) =>
                        items.gift_state === "수신안함" && items.sender !== null
                    )
                    .map((coupon, index) => (
                      <React.Fragment key={"coupon" + index}>
                        <Col
                          lg={12}
                          xxl={12}
                          md={12}
                          sm={12}
                          xs={12}
                          key={coupon.id}
                          className=""
                        >
                          <Item
                            visible={true}
                            className="w-md-480"
                            style={{
                              backgroundColor: "#fff",
                              opacity: coupon.state === "사용안함" ? 1 : 0.5,
                            }}
                          >
                            <div className="d-flex justify-content-between px-2 border-bottom align-items-center">
                              <div
                                className="card-title pt-3"
                                style={{ fontWeight: "600", maxWidth: "65%" }}
                              >
                                {coupon.coupon.title}
                              </div>
                              <p
                                className="fw-bold mt-2"
                                style={{
                                  opacity:
                                    coupon.state === "사용안함" ? 1 : 0.5,
                                  color: "#f64757",
                                }}
                              >
                                {coupon.state}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between px-2 border-bottom">
                              <div
                                className="card-title pt-3"
                                style={{ fontWeight: "600", color: "#f64757" }}
                              >
                                {coupon.coupon.discount_type === "price"
                                  ? `${coupon.coupon.discount_amount.toLocaleString()}원 할인쿠폰`
                                  : `${coupon.coupon.discount_rate}% 할인쿠폰`}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between px-2 border-bottom">
                              <div
                                className="card-title pt-3"
                                style={{ fontWeight: "400", color: "#5d5d5d" }}
                              >
                                {coupon.coupon.min_purchase.toLocaleString()}원
                                이상 구매시 사용가능
                              </div>
                            </div>

                            <div className="d-flex justify-content-between px-2 border-bottom">
                              <div className="card-text pt-3 pb-2" style={{}}>
                                만료기한 {coupon.coupon.expire_date}
                              </div>
                            </div>

                            <div className="d-flex justify-content-between px-2">
                              <div
                                className="card-title pt-3"
                                style={{ fontWeight: "400", color: "#5d5d5d" }}
                              >
                                {coupon.sender !== null &&
                                coupon.sender_phone_num === user.phone ? (
                                  <>
                                    {coupon.sender_username}님이 선물하신
                                    쿠폰입니다.
                                  </>
                                ) : null}
                              </div>
                            </div>

                            <div className="d-flex px-2">
                              <div className="mt-1" style={{ width: "100%" }}>
                                <p
                                  className="card-text"
                                  style={{ width: "100%" }}
                                >
                                  {/* <FiCalendar size={18} color={"#1d1d1d"} /> */}
                                  <span style={{}}>
                                    받은 쿠폰은 마이페이지 {">"} 쿠폰함 {">"} 내
                                    쿠폰 에서 확인하실 수 있습니다.{" "}
                                  </span>
                                </p>

                                {/* {coupon.coupon.bound_type === "reservation" ? (
                                  <>
                                    <p className="card-text mb-1">예약가능기간</p>
                                    <p className="card-text pb-3">
                                      {coupon.coupon.use_start_date} ~ {coupon.coupon.use_end_date}
                                    </p>
                                  </>
                                ) : null} */}
                                <p
                                  className="fw-bold mt-2 btn"
                                  style={{
                                    opacity:
                                      coupon.state === "사용안함" ? 1 : 0.5,
                                    color: "#f64757",
                                    border: "1px solid #f64757",
                                  }}
                                  onClick={() => {
                                    setSelectCoupon(coupon);
                                    setInfoModal(true);
                                  }}
                                >
                                  상세보기
                                </p>

                                <p
                                  className="fw-bold mt-2 btn btn-primary"
                                  style={{
                                    opacity:
                                      coupon.state === "사용안함" ? 1 : 0.5,
                                    float: "right",
                                  }}
                                  onClick={() => {
                                    console.log("gift", coupon);
                                    receptCoupon(coupon.id)
                                      .then((data) => {
                                        console.log("data", data);
                                        if (data.status === "receive ok") {
                                          alert("선물을 받았습니다.");
                                          refetch();
                                        } else {
                                          alert(
                                            "수락에 실패했습니다. 다시 시도해주세요."
                                          );
                                        }
                                      })
                                      .catch((err) => {
                                        console.log("err", err.error);
                                        alert(
                                          "수락에 실패했습니다. 다시 시도해주세요."
                                        );
                                      });
                                  }}
                                >
                                  선물수락
                                </p>
                              </div>
                            </div>
                          </Item>
                        </Col>
                      </React.Fragment>
                    ))}

                  <CouponInfoModal
                    setVisible={setInfoModal}
                    visible={infoModal}
                    info={selectCoupon}
                    setInfo={setSelectCoupon}
                    navigate={navigate}
                    needRefresh={refetch}
                  />
                </React.Fragment>
              ))}
            </>
          ) : null}

          {/* {!isLoading && data.pages.length === 0 ? (
            <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
              다운받을 쿠폰이 없습니다.
            </div>
          ) : null} */}

          {hasNextPage ? (
            <p
              className="fw-bold mt-2 btn"
              style={{ color: "#f64757", border: "1px solid #f64757" }}
              onClick={() => {
                fetchNextPage();
              }}
            >
              다음
            </p>
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default CouponGift;
