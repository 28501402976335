/* global kakao */
import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Card, CardHeader, Button } from "reactstrap";
import cn from "classnames";
import { KakaoMapModal } from "../../Components/Common/ManagerModal";

import { SITE_ID } from "../../assets/meta/meta";
import { API, BACKEND_HOST } from "../../api";

const MapSection = styled.div`
  flex: 1;
  height: 400px;
  /* height:600px; */
`;

const { kakao } = window;

const KakaoMap = (props) => {
  const navigate = useNavigate();

  const [kakaoMapEditModal, setKakaoMapEditModal] = React.useState(false);
  const [coordinate, setCoordinate] = React.useState({
    "lat": 0,
    "lng": 0
  });
  const [mapAddress, setMapAddress] = React.useState("");
  const [siteName, setSiteName] = React.useState("");
  const [siteLogo, setSiteLogo] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");

  useEffect(() => {
    API.get(`/sites/coordinate/${SITE_ID}/`)
      .then((response) => response.data)
      .then((data) => {
        if (data && Object.keys(data).length > 0) {
          // console.log("map data: ", data);
          if (!data.map_marker || !data.address || !data.name) {
            console.warn("데이터가 잘못되었습니다.", data);
          }

          setCoordinate(data.map_marker);
          setMapAddress(data.address);
          setSiteName(data.name);
          setSiteLogo(data.logo);
        }
      })
      .catch((error) => {
        setErrorMsg("사이트 데이터 로드 실패");
        console.log("error on /sites/coordinate", error);
      });
  }, []);

  useEffect(() => {
    if (!coordinate || !mapAddress || !coordinate?.lat || !coordinate?.lng) {
      return;
    }
    try {
      let container = document.getElementById("map");

      let options = {
        center: new window.kakao.maps.LatLng(Number(coordinate.lat), Number(coordinate.lng)),
        level: 4,
      };
      let map = new window.kakao.maps.Map(container, options);

      let marker = new kakao.maps.Marker({
        position: options.center,
      });
      marker.setMap(map);
      var iwContent = `<div style="padding:20px 15px;">
      <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 12px; height: 32px; margin-bottom: 4px;">
        <img src="${siteLogo}" style="width:32px; height:32px; display:${siteLogo ? "block" : 'none'}"/>
        <span style="text-align:center;">${siteName}</span>
      </div>
      <p style="font-size: 16px; color: #222;">${mapAddress}</p>
      </div>`, // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
        iwRemoveable = true; // removeable 속성을 ture 로 설정하면 인포윈도우를 닫을 수 있는 x버튼이 표시됩니다

      // 인포윈도우를 생성합니다
      var infowindow = new kakao.maps.InfoWindow({
        content: iwContent,
        removable: iwRemoveable,
      });

      // 마커에 클릭이벤트를 등록합니다
      kakao.maps.event.addListener(marker, "click", function () {
        // 마커 위에 인포윈도우를 표시합니다
        infowindow.open(map, marker);
      });
      infowindow.open(map, marker);
    } catch (error) {
      setErrorMsg("지도 로드 실패");
      console.log("error on Kakao Map", error);
    }
  }, [coordinate, mapAddress, siteName, siteLogo]);

  if (!coordinate || !mapAddress || !coordinate?.lat || !coordinate?.lng) {
    return (
      <Card className={cn("Map")}>
        <CardHeader>
          {!!props.permission && props.permission.permission > 0 ? (
            <Button
              className="btn btn-soft-primary position-absolute top-0 end-0"
              onClick={() => setKakaoMapEditModal(!kakaoMapEditModal)}
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f1a", boxShadow: "none", border: "none", fontSize: "13px", color: "#f6475f", marginRight: "10px" }}
            >
              지도 위치 수정
            </Button>
          ) : null}
        </CardHeader>
      </Card>
    )
  }

  return (
    <Card className={cn("Map")}>

      <strong style={{ fontFamily: "Gmarket-M", fontSize: "22px", textAlign: "center", color: "#f6475f", display: "block" }}>오시는 길</strong>
      <CardHeader>
        {!!props.permission && props.permission.permission > 0 ? (
          <Button
            className="btn btn-soft-primary position-absolute top-0 end-0"
            onClick={() => setKakaoMapEditModal(!kakaoMapEditModal)}
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f1a", boxShadow: "none", border: "none", fontSize: "13px", color: "#f6475f", marginRight: "10px" }}
          >
            지도 위치 수정
          </Button>
        ) : null}
      </CardHeader>

      {Object.keys(coordinate).length !== 0 ? <MapSection className={cn("MapContainer card-title mb-0 w-100  d-flex flex-md-column flex-fill")} id="map" /> : <div></div>}

      <KakaoMapModal visible={kakaoMapEditModal} setVisible={setKakaoMapEditModal} setAddress={setMapAddress} navigate={navigate} />
    </Card>
  );
};

export default KakaoMap;
