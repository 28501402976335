import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Table,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Label,
  Row,
  Col,
  Spinner,
  Container,
} from "reactstrap";

import { QRCodeSVG } from "qrcode.react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BACKEND_HOST } from "../../../api";
import TicketGiftModal from "../../../Components/modal/TicketGiftModal";
import moment from "moment";
import ShoppingRefundModal from "../../../Components/modal/ShoppingRefundModal";
import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import Loader from "../../../Components/Common/Loader";
import Navbar from "../../../Components/Common/navbar";
import { getAccessToken } from "../../../api/auth";
import { postShopOrderCancelById } from "../../../api/service";

const Item = styled.div`
  border-radius: 0.5vh;
  /* max-width: 480px; */
  padding: 10px 20px;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const MoreButton = styled.button`
  border-radius: 0.5vh;
  /* max-width: 480px; */
  padding: 7px 12px;
  background-color: white;
  font-family: "Gmarket-M";
  box-shadow: none;
  border: 1px solid #ff5a5f;
  color: #ff5a5f;
`;

function ShoppingPurchaseList(props) {
  const navigate = useNavigate();

  const [modalBackdrop, setModalBackdrop] = React.useState(false);
  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const [ticketModal, setTicketModal] = React.useState(false);
  const [selectOrder, setSelectOrder] = React.useState({});
  const [orders, setOrders] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [dataQueryString, setDataQueryString] = useState({
    filter_state: "결제완료",
  });
  const accessToken = getAccessToken();

  const fetchData = useCallback(
    async ({ pageParam = 1 }) => {
      let _queryString = dataQueryString;
      if (_queryString) {
        _queryString = `&${_queryString}`;
      } else {
        _queryString = "";
      }
      // console.log("queryString", _queryString);
      const response = await axios.get(
        `${BACKEND_HOST}/shop/my-order-list/?page=${pageParam}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      // console.log("orderdata", data);
      return { ...data, results: data.results };
    },
    [accessToken, dataQueryString]
  );

  const {
    status,
    data,
    error,
    isSuccess,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    refetch,
  } = useInfiniteQuery(
    ["ShoppingOrderList", dataQueryString],
    async ({ pageParam = 1 }) => {
      // console.log("pageParam", pageParam);
      const data = await fetchData({ pageParam });
      return data;
    },
    {
      // getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.next) {
          const match = lastPage.next.match(/page=(\d+)/);
          if (match) {
            return match[1];
          }
        }
        return undefined;
      },
      // getPreviousPageParam: (firstPage) => undefined,
    }
  );

  const goCancel = async (id) => {
    try {
      postShopOrderCancelById(id);
    } catch (e) {
      console.error("e " + e);
    } finally {
      setTicketModal(false);
      navigate(0);
    }
  };

  useEffect(() => {
    console.log("data", data);
  }, [data]);
  return (
    <React.Fragment>
      <div
        className="layout-wrapper landing"
        style={{ backgroundColor: "#fff9fa" }}
      >
        <div
          style={{
            maxWidth: "640px",
            margin: "0 auto",
            backgroundColor: "#fff",
          }}
        >
          <Navbar />
          <Container
            fluid
            style={{
              minHeight: "100vh",
              backgroundColor: "white",
              paddingBottom: "100px",
            }}
          >
            <Row>
              <Col className="col-12 d-flex flex-wrap justify-content-center gap-4 mt-4">
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "50vh" }}
                  >
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : null}

                {data && isSuccess ? (
                  <>
                    {data?.pages.map((page, index) => (
                      <React.Fragment key={index}>
                        {page.results.map((order, index) => (
                          <React.Fragment key={"order" + index}>
                            <Col
                              lg={12}
                              xxl={12}
                              md={12}
                              sm={12}
                              xs={12}
                              key={order.id}
                              className=""
                            >
                              <Item
                                // visible={!order.gifted}
                                visible={true}
                                className="bg-white"
                              >
                                <div className="d-flex justify-content-between px-2 border-bottom pt-2 align-items-center pb-2">
                                  {order.delivery ? (
                                    <p
                                      className="card-title fw-bold"
                                      style={{ margin: 0 }}
                                    >
                                      {order.delivery.state}
                                    </p>
                                  ) : (
                                    <p
                                      className="card-title fw-bold"
                                      style={{ margin: 0 }}
                                    >
                                      {order.state}
                                    </p>
                                  )}
                                  <p
                                    className="text-muted fs-14"
                                    style={{ margin: 0 }}
                                  >
                                    {moment(order.order_date).format(
                                      "YYYY-MM-DD HH:mm"
                                    )}
                                  </p>
                                </div>
                                {order.shopping_products.map(
                                  (product, index) => (
                                    <div
                                      className="d-flex py-3 align-items-flex-start justify-content-start mt-2"
                                      key={"product" + index}
                                      style={{
                                        borderBottom: "1px solid #ededed",
                                      }}
                                    >
                                      <div className="avatar-md mx-2">
                                        <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                          <img
                                            src={`${product.thumbnail}`}
                                            alt=""
                                            className="img-fluid"
                                            style={{ borderRadius: "5px" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="mt-0">
                                        <div
                                          className="card-title fw-bold"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          {product.name}
                                        </div>
                                        {product.items &&
                                          product.items.map((item, index) =>
                                            item.variation ? (
                                              <div
                                                className=""
                                                style={{
                                                  marginLeft: "10px",
                                                  marginBottom: "5px",
                                                }}
                                                key={"item" + index}
                                              >
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    color: "#5d5d5d",
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {item.state}
                                                </p>
                                                <div
                                                  style={{
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    {item.variation.value} (
                                                    {item.variation.is_required
                                                      ? (
                                                          item.variation.price +
                                                          item.price
                                                        ).toLocaleString()
                                                      : item.variation.price.toLocaleString()}
                                                    원 · {item.quantity}개)
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              <div
                                                className=""
                                                style={{
                                                  marginLeft: "10px",
                                                  marginBottom: "5px",
                                                }}
                                                key={"item" + index}
                                              >
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    color: "#5d5d5d",
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  {item.state}
                                                </p>
                                                <div
                                                  style={{
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    {item.price.toLocaleString()}
                                                    원 · {item.quantity}개
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  )
                                )}
                                {/* <div className="d-flex py-3 align-items-center justify-content-start">
                            <div className="avatar-md mx-2">
                              <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                <img src={`${order.shopping_product.thumbnail}`} alt="" className="img-fluid" style={{ borderRadius: "5px" }} />
                              </div>
                            </div>
                            <div className="mt-1">
                              <div className="card-title fw-bold" style={{ marginLeft: "10px" }}>
                                {order.name}
                              </div>
                              {order.variation ? (
                                <div className="card-text text-muted" style={{ marginLeft: "10px" }}>
                                  <span>{order.variation.value}</span>
                                </div>
                              ) : null}
                              {order.variation ? (
                                order.variation.is_required ? (
                                  <div className="card-text text-muted" style={{ marginLeft: "10px" }}>
                                    {(order.price + order.variation.price).toLocaleString()}원 · {order.quantity}개
                                  </div>
                                ) : (
                                  <div className="card-text text-muted" style={{ marginLeft: "10px" }}>
                                    {order.variation.price.toLocaleString()}원 · {order.quantity}개
                                  </div>
                                )
                              ) : (
                                <div className="card-text text-muted" style={{ marginLeft: "10px" }}>
                                  {order.price.toLocaleString()}원 · {order.quantity}개
                                </div>
                              )}
                            </div>
                          </div> */}
                                {order.delivery &&
                                [
                                  "배송준비중",
                                  "배송중",
                                  "배송완료",
                                  "취소요청",
                                  "취소완료",
                                ].includes(order.delivery.state) ? (
                                  <div
                                    style={{
                                      marginLeft: "10px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p style={{ fontWeight: "600" }}>
                                        배송상태
                                      </p>
                                      <p className="text-muted">
                                        {order.delivery.state}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p style={{ fontWeight: "600" }}>
                                        운송정보
                                      </p>
                                      <p className="text-muted">
                                        {order.delivery.invoice_info}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p style={{ fontWeight: "600" }}>
                                        운송번호
                                      </p>
                                      <p className="text-muted">
                                        {order.delivery.invoice_num}
                                      </p>
                                    </div>
                                  </div>
                                ) : null}

                                {order.delivery &&
                                order.delivery.state === "주문접수" ? (
                                  <div className="d-flex justify-content-end pb-4 pt-3">
                                    <Button
                                      style={{
                                        fontFamily: "Gmarket-M",
                                        backgroundColor: "#fff",
                                        borderColor: "#ff5a5f",
                                        color: "#ff5a5f",
                                      }}
                                      color="danger"
                                      onClick={(e) => {
                                        setSelectOrder(order);
                                        setTicketModal(true);
                                      }}
                                    >
                                      주문취소
                                    </Button>
                                  </div>
                                ) : null}
                              </Item>
                            </Col>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </>
                ) : null}

                {hasNextPage && (
                  <MoreButton
                    disabled={isFetchingNextPage}
                    onClick={() => fetchNextPage()}
                  >
                    더보기
                  </MoreButton>
                )}

                {!isLoading && orders.length === 0 && !hasNextPage ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ alignItems: "center", height: 150 }}
                  >
                    <Loader />
                  </div>
                ) : null}
              </Col>

              <ShoppingRefundModal
                setVisible={setTicketModal}
                visible={ticketModal}
                info={selectOrder}
                setInfo={setSelectOrder}
                navigate={navigate}
                goCancel={goCancel}
              />
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShoppingPurchaseList;
