import React from "react";
import {
  Card,
  Table,
  Button,
  ButtonGroup,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import ReservationGiftModal from "../../../../Components/modal/ReservationGiftModal";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { API, BACKEND_HOST } from "../../../../api";

import { FiCalendar } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa6";
import { IoGiftOutline } from "react-icons/io5";
import { SUBDOMAIN } from "../../../../assets/meta/meta";
import QueryString from "qs";
import { getAccessToken } from "../../../../api/auth";
import {
  deleteReservationGiftById,
  getReservationSendGiftByPage,
} from "../../../../api/service";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  /* max-width: 480px; */
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const FilterBadge = styled.div`
  border-radius: 100px;
  padding: 0.4rem 0.8rem;
  user-select: none;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#f6475f" : "#e9ecef")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  font-family: "Gmarket-M";
  font-size: 12px;
`;

function ReservationSendGift({ todayOnly }) {
  const navigate = useNavigate();

  const [giftModal, setGiftModal] = React.useState(false);
  const [selectOrder, setSelectOrder] = React.useState({});
  const [orders, setOrders] = React.useState([]);
  const [isCancel, setIsCancel] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);
  const [queryString, setQueryString] = React.useState({
    filter_site: SUBDOMAIN,
    filter_states: "예약,입장,외출,퇴장",
  });

  React.useEffect(() => {
    setdata();
  }, [queryString]);

  const setdata = async (page) => {
    try {
      if (!page) page = 1;
      const accessToken = getAccessToken();
      setIsLoading(true);

      let _queryString = QueryString.stringify(queryString);
      if (_queryString) _queryString = `&${_queryString}`;
      else _queryString = "";

      const response = await getReservationSendGiftByPage({
        page: page,
        queryString: _queryString,
      });

      const data = response.data;
      // console.log("fetching reservations", data);
      // 예약일이 임박한 것이 먼저 보임. 사용불가는 젤 밑에 보임.
      setHasMore(data.hasMore);

      let _orders = data.results
        .sort((a, b) => {
          const d1 = moment(a.reservationDate);
          const d2 = moment(b.reservationDate);
          return d1 > d2 ? -1 : +1;
        })
        .sort((a, b) => {
          // return !a.gifted && a.state !== "입장" ? -1 : +1
          return !a.gifted &&
            !["입장", "퇴장", "외출", "완료", "노쇼"].includes(a.state)
            ? -1
            : +1;
        });

      if (todayOnly)
        _orders = _orders.filter(
          (item) =>
            moment(item.reservationDate).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
        );

      if (page === 1) {
        setPage(1);
        setOrders(_orders);
      } else {
        setPage(page);
        setOrders((prev) => [...prev, ..._orders]);
      }
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    } finally {
      setIsLoading(false);
    }
  };

  const handelCancelGift = async (reservId) => {
    try {
      if (window.confirm("예약상품의 선물을 취소하시겠습니까?")) {
        const accessToken = getAccessToken();

        const response = await deleteReservationGiftById(reservId);

        const data = response.data;
        console.log(data);
        navigate(0);
      }
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col
          className="col-12 d-flex flex-wrap justify-content-center gap-4"
          style={todayOnly && orders.length ? { marginBottom: "16px" } : {}}
        >
          {(orders || []).map((order, key) => (
            <Col
              lg={12}
              xxl={12}
              md={12}
              sm={12}
              xs={12}
              key={order.id}
              className=""
            >
              <Item visible={true} className="bg-white w-md-480">
                <div className="d-flex justify-content-between px-2 border-bottom">
                  <div
                    className="card-title pt-3"
                    style={{ fontWeight: "600" }}
                  >
                    {order.name}
                  </div>
                </div>
                <div className="d-flex py-3 px-2">
                  <div className="mt-1" style={{ width: "100%" }}>
                    <p
                      className="card-text border-bottom py-2"
                      style={{ width: "100%" }}
                    >
                      <FiCalendar size={18} color={"#1d1d1d"} />
                      <span style={{ marginLeft: "10px", lineHeight: "32px" }}>
                        {order.reservationDate}{" "}
                      </span>
                    </p>
                    <p className="card-text">
                      <FaRegUser size={16} color={"#1d1d1d"} />
                      <span style={{ marginLeft: "10px", lineHeight: "32px" }}>
                        {order.spot}번
                      </span>
                    </p>
                    {order.options.length ? (
                      <div className="card-text border-top py-3">
                        <span style={{ fontWeight: "600" }}>추가 옵션</span>
                        <br />
                        <br />
                        {order.options.map((item, idx, obj) => {
                          return (
                            <div key={item.id}>
                              <div
                                className="border-bottom py-2"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {item.name}
                                <span style={{ textAlign: "right" }}>
                                  {item.price.toLocaleString()}원
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
                {!order.gifted &&
                !["입장", "퇴장", "외출", "완료", "노쇼"].includes(
                  order.state
                ) ? (
                  <div className="d-flex w-100 flex-column pb-3">
                    <div
                      className="d-flex gap-2 pb-2"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        style={{
                          fontFamily: "Gmarket-M",
                          backgroundColor: "#fff",
                          borderColor: "#ff5a5f",
                          color: "#ff5a5f",
                        }}
                        onClick={(e) => {
                          setIsCancel(false);
                          setSelectOrder(order);
                          setGiftModal(true);
                        }}
                      >
                        선물하기
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex w-100 align-items-center pb-3 border-top pt-3 px-2 justify-content-between">
                    {order.gift && order.gift["username"] ? (
                      <div className="card-text">
                        <IoGiftOutline
                          size={18}
                          color="#1d1d1d"
                          style={{ marginRight: "5px" }}
                        />{" "}
                        {order.gift["username"]}
                      </div>
                    ) : null}
                    {!!order.gifted ? (
                      <span
                        style={{ fontFamily: "Gmarket-M", color: "#ff5a5f" }}
                      >
                        선물완료
                      </span>
                    ) : null}
                    {/* <span style={{ margin: "8px" }}></span> */}
                    {order.gifted === "선물발신" ? (
                      <Button
                        color="danger"
                        onClick={(e) => {
                          handelCancelGift(order.id);
                        }}
                      >
                        선물취소
                      </Button>
                    ) : null}
                  </div>
                )}
              </Item>
            </Col>
          ))}

          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          ) : null}

          {!isLoading && orders.length === 0 && !todayOnly ? (
            <div
              className="d-flex justify-content-center"
              style={{ alignItems: "center", height: 150 }}
            >
              선물가능한 예약이 없습니다.
            </div>
          ) : null}
        </Col>
      </Row>

      <ReservationGiftModal
        setVisible={setGiftModal}
        visible={giftModal}
        info={selectOrder}
        setInfo={setSelectOrder}
        navigate={navigate}
        needRefresh={setdata}
      />
    </React.Fragment>
  );
}

export default ReservationSendGift;
