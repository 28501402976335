import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Button } from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API, BACKEND_HOST } from "../../../api";
import moment from "moment";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { useUserStore } from "../../../store/store";
import useElementSize from "../../../hooks/useElementSize";
import { RxLockClosed } from "react-icons/rx";
import { FiAlertCircle, FiBell } from "react-icons/fi";
import { RiQuestionLine } from "react-icons/ri";
import { SITE_ID } from "../../../assets/meta/meta";
import qs from "qs";
import { getAccessToken } from "../../../api/auth";
import InquiryPagination from "./InquiryPagination";
import SearchBox from "./SearchBox";
import InquiryBadgeList from "./InquiryBadgeList";
import { useQuery } from "react-query";

const InquiryListSection = (props) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 1;
  const setPage = useCallback((page) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev), page }));
  }, [setSearchParams]);
  const pageReset = useCallback(() => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev), page: 1 }));
  }, [setSearchParams]);
  const queryString = useMemo(() => {
    // console.log("searchParams", Object.fromEntries(searchParams));
    return Object.fromEntries(searchParams);
  }, [searchParams]);
  const setQueryString = useCallback((query) => {
    if (typeof query === "function") {
      setSearchParams((prev) => {
        const newQuery = query(Object.fromEntries(prev));
        return newQuery;
      });
    } else {
      setSearchParams(query);
    }
  }, [setSearchParams]);
  // inquiryQueryStrings: {
  //   modes: "1,2,3,7",
  //   // phone_num: "",
  //   // search: "",
  //   // is_notice: "False",
  //   // need_extra_info: "",
  //   // no_reply: "",
  //   // my_article: "",
  // },

  const [permission, user] = useUserStore((state) => [state.permission, state.user]);
  const [availableSites, setAvailableSites] = useUserStore((state) => [state.availableSites, state.setAvailableSites]);

  const [{ width: tableElementWidth }, tableRef] = useElementSize();
  const tableWidth = (() => {
    // console.log("tableElementWidth", tableElementWidth);
    if (window.innerWidth >= 1200) {
      // console.log("1200");
      return 640 - 40;
    } else if (window.innerWidth >= 992) {
      // console.log("992");
      return 640 - 20;
    } else if (window.innerWidth >= 768) {
      // console.log("768");
      return 640 - 40;
    } else if (window.innerWidth >= 576) {
      // console.log("576");
      return 576 - 80;
    }
    return tableElementWidth - 20;
  })();

  const [inquiryCount, setInquiryCount] = useState(0);
  const [inquiryNext, setInquiryNext] = useState(null);
  const [inquiryPrevious, setInquiryPrevious] = useState(null);

  const [noticeList, setNoticeList] = useState([]);

  const [isAdminMode, setIsAdminMode] = useUserStore((state) => [state.adminInquiry, state.setAdminInquiry]);
  const [adminTargetSubdomain, setAdminTargetSubdomain] = useUserStore((state) => [state.adminTargetSubdomain, state.setAdminTargetSubdomain]);

  const [headBanners, setHeadBanners] = useState(null);
  const [siteName, setSiteName] = useState("");

  const urlQuery = useMemo(() => {
    const queryStrings = {
      ...queryString,
    };
    let _query = qs.stringify(queryStrings);
    return _query;
  }, [queryString]);

  useEffect(() => {
    let _subdomain = SUBDOMAIN;
    if (isAdminMode && adminTargetSubdomain) {
      _subdomain = adminTargetSubdomain;
    } else if (isAdminMode) {
      _subdomain = "";
    } else {
      _subdomain = SUBDOMAIN;
    }
    setQueryString((prev) => {
      return { ...prev, subdomain: _subdomain };
    });
  }, [setQueryString, isAdminMode, adminTargetSubdomain]);

  const fetchInquiryList = async () => {
    const accessToken = getAccessToken();

    let _query = urlQuery;
    if (_query) _query = `?modes=1,2,3,7&${_query}`;

    const response = await API.get(`/inquiry/article-list/${_query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    setInquiryCount(data.count);
    setInquiryNext(data.next);
    setInquiryPrevious(data.previous);

    return data.results;
  };

  const { data: inquiries, isLoading, isError } = useQuery({
    queryKey: ["inquiryList", queryString, isAdminMode, adminTargetSubdomain],
    queryFn: fetchInquiryList,
  });

  const columns = useMemo(() => {
    return [
      // {
      //   Header: "번호",
      //   accessor: "id",
      //   width: 50,
      // },
      // {
      //   Header: "공지",
      //   accessor: "is_notice",
      //   width: 50,
      //   Cell: (cellProps) => {
      //     return cellProps.row.original["is_notice"] ? "O" : "";
      //   },
      // },
      {
        Header: "제목",
        accessor: "title",
        width: tableWidth > 576 ? tableWidth - 260 : tableWidth - 120, // - 100 - 50 - 120
        Cell: (cellProps) => {
          return (
            <Link to={`/inquiry/detail/${cellProps.row.original.id}?${urlQuery}`}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ maxWidth: tableWidth > 576 ? tableWidth - 260 : tableWidth - 120, overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {!!permission?.permission && cellProps.row.original["need_extra_info"] ? <FiAlertCircle size="20" style={{ marginTop: "-4px", color: "#f06548" }} /> : null}

                  {(!cellProps.row.original["comment_count"] && moment(cellProps.row.original["written_date"]) > moment().subtract(1, "days")) ||
                    (!!cellProps.row.original["latest_comment_date"] && moment(cellProps.row.original["latest_comment_date"]) > moment().subtract(1, "days")) ? (
                    <i className="mdi mdi-new-box fs-22"></i>
                  ) : null}

                  {cellProps.row.original["is_notice"] ? "[공지] " : ""}

                  {!!permission?.permission && !["4", "5", "6"].includes(cellProps.row.original["mode"]) && !cellProps.row.original["is_latest_comment_by_staff"] ? (
                    <FiBell size="20" style={{ marginTop: "-4px", color: "#f06548" }} />
                  ) : null}

                  {["2", "1"].includes(cellProps.row.original["mode"]) ? <RxLockClosed size="20" style={{ marginTop: "-4px" }} /> : null}

                  <span>&nbsp;</span>
                  {isAdminMode ? <span style={{ color: "#F6475F", marginRight: "10px", fontSize: "13px" }}>{cellProps.row.original["site"]}</span> : null}
                  {cellProps.row.original["title"]}
                </div>

                {!["4", "5", "6"].includes(cellProps.row.original["mode"]) && cellProps.row.original["comment_count"] > 0 ? (
                  <>
                    <span>&nbsp;</span>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        width: "20px",
                        height: "20px",
                        borderRadius: "100px",
                        fontSize: "12px",
                        backgroundColor: "#F6475F",
                        color: "white",
                      }}
                    >
                      <div style={{ width: "20px", height: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>{cellProps.row.original["comment_count"]}</div>
                    </div>
                  </>
                ) : null}
              </div>
            </Link>
          );
        },
      },
      tableWidth > 576
        ? {
          Header: "작성일",
          accessor: "written_date",
          width: 100,
          Cell: (cellProps) => {
            return moment(cellProps.row.original["written_date"]).format("YYYY-MM-DD");
          },
        }
        : {
          Header: "",
          accessor: "written_date",
          width: 0,
          Cell: (cellProps) => {
            return <span style={{ display: "none" }}>{moment(cellProps.row.original["written_date"]).format("YYYY-MM-DD")}</span>;
          },
        },
      {
        Header: "",
        accessor: "phone_num",
        width: 0,
        Cell: (cellProps) => {
          return <span style={{ display: "none" }}>{cellProps.row.original["phone_num"]}</span>;
        },
      },
      {
        Header: "작성자",
        accessor: "user_full_name",
        accessorFn: (cellProps) => {
          return cellProps.row.original["user_full_name"];
        },
        width: 50,
        Cell: (cellProps) => {
          if (!!cellProps.row.original["user_permission"]?.permission) {
            return <span>관리자</span>;
          }
          let _username = cellProps.row.original["user_full_name"];
          _username = user && cellProps.row.original["user_id"] === String(user.id) ? _username : _username[0] + "*".repeat(_username.length - 1);

          return <span>{_username}</span>;
        },
      },
    ]
  }, [tableWidth, isAdminMode, user, permission, urlQuery]);

  useEffect(() => {
    if (isAdminMode) {
      setNoticeList([]);
      return;
    }

    const fetchArticles = async () => {
      const accessToken = getAccessToken();
      const _subdomain = SUBDOMAIN;
      try {
        const response = await API.get(`/inquiry/articles/?subdomain=${_subdomain}&modes=4,5,6`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = response.data;
        // console.log(data);
        if (data.length === 0) return;
        setNoticeList(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchArticles();
  }, [isAdminMode]);

  useEffect(() => {
    let _subdomain = window.location.host.split(".")[0];
    // console.log("subdomain", _subdomain);
    if (_subdomain.startsWith("localhost")) _subdomain = "test";
    const fetchHeadBanners = async () => {
      try {
        const response = await API.get(`/inquiry/head-banner/`, {
          // const response = await axios.get(`${BACKEND_HOST}/media/inquiry/inquiry_head.json?cache_date=${moment().format('YYYY-MM-DD_HH_mm')}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = response.data;
        // console.log(data);
        if (data.datas.length === 0) return;

        const banners = data.datas.filter((item) => item.subdomain === _subdomain);
        // console.log(banners);
        if (banners && banners.length) setHeadBanners(banners);
      } catch (error) {
        console.log(error);
        // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
      }
    };

    fetchHeadBanners();
  }, []);

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const response = await API.get(`/sites/camps/${SITE_ID}/`);
        const data = response.data;
        setSiteName(data.name);
      } catch (error) {
        console.log(error);
        // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
      }
    };
    fetchSiteData();
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <Container className="mt-4 pb-5" style={{ minHeight: "100vh" }}>
        {!!headBanners && headBanners.length
          ? headBanners.map((headBanner, i) => {
            return (
              <Row key={i}>
                <Card id="inquiryBanner">
                  <div className="card-body pt-0 ">
                    {headBanner.type === "imagebanner" ? (
                      <div className="row" style={{}}>
                        <a href={headBanner.url} target="_self" rel="noreferrer">
                          {/* <div>
                              <img
                                src={headBanner.background}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "auto",
                                }}
                              />
                            </div> */}
                          <div style={{ backgroundColor: "#fff9fa", padding: "30px 20px 30px 20px", borderRadius: "15px" }}>
                            <h4 style={{ fontFamily: "Gmarket-M", color: "#f6475f", textAlign: "center" }}>{siteName} 리뷰 모아보기</h4>
                            <p style={{ fontFamily: "Gmarket-M", textAlign: "center" }}>이용 고객들의 후기를 확인해 보세요!</p>
                            <div
                              className="banner-item-group"
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {headBanner.subitems && headBanner.subitems.length > 1 ? (
                                <>
                                  <div className="banner-item" style={{ width: "33%", maxWidth: "130px" }}>
                                    {/* <a href={headBanner.subitems[0].url} target="_blank"> */}
                                    <a href={headBanner.subitems[0].url} target="_blank" rel="noreferrer">
                                      {/* <img src={headBanner.subitems[0].background} alt="" style={{ width: "100%" }} /> */}
                                      <div
                                        style={{
                                          backgroundColor: "#f6475f",
                                          color: "#fff",
                                          fontSize: "14px",
                                          fontFamily: "Gmarket-M",
                                          padding: "8px 10px",
                                          textAlign: "center",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        리뷰1
                                      </div>
                                    </a>
                                  </div>
                                  <div className="banner-item" style={{ width: "33%", maxWidth: "130px" }}>
                                    <a href={headBanner.subitems[1].url} target="_blank" rel="noreferrer">
                                      {/* <img src={headBanner.subitems[1].background} alt="" style={{ width: "100%" }} /> */}
                                      <div
                                        style={{
                                          backgroundColor: "#f6475f",
                                          color: "#fff",
                                          fontSize: "14px",
                                          fontFamily: "Gmarket-M",
                                          padding: "8px 10px",
                                          textAlign: "center",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        리뷰2
                                      </div>
                                    </a>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </a>
                        {/* <div
                            className="banner-item-group"
                            style={{
                              position: "absolute",
                              bottom: "17%",
                              left: "8%",
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {headBanner.subitems && headBanner.subitems.length > 1 ? (
                              <>
                                <div className="banner-item" style={{ width: "16%" }}>
                                  <a href={headBanner.subitems[0].url} target="_blank">
                                    <img src={headBanner.subitems[0].background} alt="" style={{ width: "100%" }} />
                                  </a>
                                </div>
                                <div className="banner-item" style={{ width: "16%" }}>
                                  <a href={headBanner.subitems[1].url} target="_blank">
                                    <img src={headBanner.subitems[1].background} alt="" style={{ width: "100%" }} />
                                  </a>
                                </div>
                              </>
                            ) : null}
                          </div> */}
                      </div>
                    ) : (
                      <div
                        style={{
                          minHeight: "200px",
                          width: "100%",
                          position: "relative",
                          padding: "20px",
                          borderRadius: "10px",
                          // backgroundColor: "#f5f5f5",
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "14px" }}>
                          <RiQuestionLine style={{ fontSize: "20px", marginRight: "5px" }} />
                          <h5 style={{ textAlign: "center", color: "#333", margin: "0", padding: 0, fontFamily: "Gmarket-M" }}>{headBanner.title}</h5>
                        </div>
                        <div
                          className="banner-item-group"
                          style={{
                            // position: "absolute",
                            // bottom: "17%",
                            // left: "8%",
                            width: "95%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "12px",
                            flexWrap: "wrap",
                          }}
                        >
                          {headBanner.subitems && headBanner.subitems.length > 1
                            ? headBanner.subitems.map((item, i) => {
                              return (
                                <a
                                  key={i}
                                  href={item.url}
                                  target="_self"
                                  style={{
                                    width: tableWidth > 576 ? "48%" : tableWidth > 380 ? "100%" : "100%",
                                    // width: "48%",
                                    // minWidth: "110px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#333",
                                    marginLeft: "0",
                                    // margin: "0 auto",
                                  }}
                                >
                                  <div
                                    className="banner-item"
                                    style={{
                                      width: "100%",
                                      minWidth: "120px",
                                      // height: "34px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "#fff",
                                      // borderRadius: "20px",
                                      padding: "13px 8px",
                                      borderBottom: "1px solid #ededed",

                                      // filter: "drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.1))",
                                    }}
                                  >
                                    <span>{i + 1}. </span>
                                    <span style={{ fontFamily: "Gmarket-M" }}>{item.name}</span>
                                  </div>
                                </a>
                              );
                            })
                            : null}
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              </Row>
            );
          })
          : null}
        <Row className="justify-content-center">
          <Col>
            <Card id="customerList">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  {/* <div className="col-sm-auto">
                    <div>
                      <h4 className="card-title mb-0 flex-grow-1">문의</h4>
                    </div>
                  </div> */}
                  <div className="col-sm-3 ms-auto">
                    <div className="d-flex justify-content-sm-end gap-2">
                      <Button
                        className="btn btn-soft-primary add-btn me-1"
                        style={{ border: "none", fontSize: "13px", fontFamily: "Gmarket-M" }}
                        onClick={() => {
                          if (user === null) {
                            navigate(`/login?path=/inquiry/form/0`);
                            return;
                          } else {
                            navigate(`/inquiry/form/${0}`);
                          }
                        }}
                        id="write-btn"
                      >
                        글쓰기
                      </Button>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <div className="card-body pt-0" style={{ padding: 0 }}>
                <div>
                  <InquiryBadgeList
                    query={queryString}
                    setQuery={setQueryString}
                    hasPermission={permission?.permission}
                    isTest={SUBDOMAIN === "test"}

                    user={user}
                    availableSites={availableSites}

                    isAdminMode={isAdminMode}
                    setIsAdminMode={setIsAdminMode}
                    adminTargetSubdomain={adminTargetSubdomain}
                    setAdminTargetSubdomain={setAdminTargetSubdomain}

                    pageReset={pageReset}
                  />

                  <Col md={9}>
                    <SearchBox setQuery={setQueryString} query={queryString} count={inquiryCount} />
                  </Col>

                  {inquiries && inquiries.length ? (
                    <TableContainer
                      // defaultPage={page}
                      ref={tableRef}
                      columns={columns}
                      data={[...noticeList, ...inquiries] || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      isCustomerFilter={false}
                      customPageSize={100}
                      className="custom-header-css"
                      theadClass="table-light text-muted fs-13"
                    />
                  ) : (
                    <Loader loading={isLoading} />
                  )}

                  {isError ? <div>
                    게시글 불러오기를 실패하였습니다. 다시 시도해 주세요.
                  </div> : null}

                  <InquiryPagination
                    next={inquiryNext}
                    prev={inquiryPrevious}
                    count={inquiryCount}
                    page={page}
                    setPage={setPage}
                  />
                </div>

                <ToastContainer closeButton={false} />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InquiryListSection;
