import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Label,
} from "reactstrap";
import { QRCodeSVG } from "qrcode.react";

import UserVoucherGiftModal from "../../../../Components/modal/UserVoucherGiftModal";
import { API, BACKEND_HOST } from "../../../../api";
import moment from "moment";
import Loader from "../../../../Components/Common/Loader";
import { getAccessToken } from "../../../../api/auth";
import {
  deleteCancelGiftById,
  getVoucherSendData,
  postVoucherStateById,
  postValidateVoucher,
} from "../../../../api/service";

const Item = styled.div`
  border-radius: 0.5vh;
  /* max-width: 480px; */
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  background-color: white;
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const VoucherSendGift = () => {
  const navigate = useNavigate();

  const [giftModal, setGiftModal] = React.useState(false);

  const [voucherItem, setVoucherItem] = React.useState([]);
  const [modalBackdrop, setModalBackdrop] = React.useState(false);
  const [modalDatas, setModalDatas] = React.useState([]);
  function tog_backdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const [modal_alert, setmodal_alert] = React.useState(false);
  function tog_toggle_alert() {
    setmodal_alert(!modal_alert);
  }

  React.useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      setdata();
    } else {
      navigate(`/login`);
    }
  }, []);

  const setdata = async () => {
    try {
      const response = await getVoucherSendData();

      const data = response.data;
      let _vouchers = data.vouchers;
      _vouchers = _vouchers.sort((a, b) => {
        return a.state === "사용안함" && !a.gifted ? -1 : +1;
      });
      _vouchers = _vouchers.map((item) => {
        return {
          ...item,
          remaining:
            moment(item.expire_date)
              .startOf("day")
              .diff(moment().startOf("day"), "days") + 1,
        };
      });
      setVoucherItem(_vouchers);

      const checkVouchersResponse = await postValidateVoucher();

      console.log(checkVouchersResponse.data);
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    }
  };

  const changeUserVoucherState = (itemId) => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      navigate(-1);
      return;
    }

    postVoucherStateById(itemId)
      .then((response) => {
        console.log("changeUserVoucherState", response.data);
        navigate(0);
        // setdata()
      })
      .catch((error) => {
        console.log(error);
        // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
      });
  };

  const handelCancelGift = async (itemId) => {
    if (window.confirm("선물을 취소하시겠습니까?")) {
      try {
        const response = await deleteCancelGiftById(itemId);

        console.log(response.data);
        setdata();
      } catch (error) {
        console.log(error);
        // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
      }
    }
  };

  const toggleCheckAll = () => {
    const checkall = document.getElementById("voucher-check-all");
    const ele = document.querySelectorAll(".voucher-check-input");

    if (checkall.checked) {
      ele.forEach((ele) => {
        let tmpArr = voucherItem.filter(
          (voucher) => voucher.id.toString() === ele.value.toString()
        );
        let obj = tmpArr[0];
        if (!obj.gifted && obj.state === "사용안함") ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  return (
    <React.Fragment>
      <Row>
        {/* <div className="d-flex justify-content-between">
          <div>
            <Input
              className="voucher-check-input-all"
              type="checkbox"
              id="voucher-check-all"
              onChange={() => {
                toggleCheckAll();
                changeCheckedRecords();
              }}
            />
            <Label htmlFor="voucher-check-all" className="form-label fs-14" style={{ marginLeft: "6px" }}>
              전체선택
            </Label>
          </div>
          {modalDatas.length > 1 ? (
            <Button
              onClick={() => {
                setGiftModal(true);
              }}
            >
              한번에 선물하기
            </Button>
          ) : null}
        </div> */}
        <Col className="col-12 d-flex flex-wrap justify-content-center gap-4">
          {voucherItem.length !== 0 ? (
            voucherItem
              .filter((voucher) => voucher.state === "사용안함")
              .map((item, idx) => {
                return (
                  <Col
                    lg={12}
                    xxl={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={item.id}
                    className=""
                  >
                    <Item
                      visible={item.state === "사용안함"}
                      className="bg-white"
                    >
                      <div className="d-flex justify-content-between px-2 border-bottom">
                        <div className="mt-3 d-flex flex-row gap-2">
                          {/* <Input
                          className="voucher-check-input"
                          type="checkbox"
                          id={`voucher-check-${item.id}`}
                          disabled={!(!item.gifted && item.state === "사용안함")}
                          value={item.id}
                          onChange={(e) => {
                            changeCheckedRecords();
                          }}
                        /> */}
                          <div
                            className="card-title"
                            style={{ fontWeight: "600" }}
                          >
                            {item.name}
                          </div>
                        </div>

                        {item.state === "사용안함" && !item.gifted ? (
                          <div className="fw-bold my-2 btn btn-primary">
                            사용가능
                          </div>
                        ) : (
                          <p className="text-muted pt-3">사용불가</p>
                        )}
                      </div>
                      <div className="d-flex pt-3">
                        <div
                          className="avatar-lg mx-2"
                          onClick={() => {
                            if (item.state === "사용안함" && !item.gifted) {
                              setModalDatas([item]);
                              tog_backdrop();
                            }
                          }}
                        >
                          <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                            <img
                              src={item.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="mt-1 px-3" style={{ width: "80%" }}>
                          <p
                            className="card-text border-bottom pb-2"
                            style={{ width: "100%" }}
                          >
                            {item.state}{" "}
                          </p>
                          {item.state === "사용안함" ? (
                            <p
                              className="card-text border-bottom pb-2"
                              style={{ width: "100%" }}
                            >
                              {item.remaining > 0
                                ? `잔여일수 ${item.remaining}일`
                                : "기간만료"}
                            </p>
                          ) : (
                            <p
                              className="card-text border-bottom pb-2"
                              style={{ width: "100%" }}
                            >
                              사용시간 {item.used_time}{" "}
                            </p>
                          )}

                          {item.giftable ? (
                            <div>
                              {!item.gifted && item.state === "사용안함" ? (
                                <Button
                                  style={{
                                    fontFamily: "Gmarket-M",
                                    backgroundColor: "#fff",
                                    borderColor: "#ff5a5f",
                                    color: "#ff5a5f",
                                  }}
                                  color="success"
                                  onClick={(e) => {
                                    setModalDatas([item]);
                                    setGiftModal(true);
                                  }}
                                >
                                  선물하기
                                </Button>
                              ) : null}

                              {item.gift && item.gift["username"] ? (
                                <div className="card-text text-muted">
                                  받은이: {item.gift["username"]}
                                </div>
                              ) : null}

                              {/* {!!item.gifted ? <span>선물완료</span> : null} */}
                              {item.gifted === "선물발신" &&
                              item.state === "사용안함" ? (
                                <Button
                                  style={{
                                    fontFamily: "Gmarket-M",
                                    backgroundColor: "#ff5a5f",
                                    borderColor: "#ff5a5f",
                                    color: "#fff",
                                    marginTop: "10px",
                                  }}
                                  color="danger"
                                  onClick={(e) => {
                                    handelCancelGift(item.id);
                                  }}
                                >
                                  선물취소
                                </Button>
                              ) : null}
                            </div>
                          ) : null}
                          <span style={{ margin: "8px" }}></span>
                        </div>
                      </div>
                    </Item>
                  </Col>
                );
              })
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ alignItems: "center", height: 150 }}
            >
              <Loader />
            </div>
          )}
        </Col>
      </Row>

      <Modal
        isOpen={modalBackdrop}
        toggle={() => {
          setModalDatas([]);
          tog_backdrop();
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalDatas([]);
              setModalBackdrop(false);
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody className="text-center p-5">
          {/* <lord-icon src="https://cdn.lordicon.com/lupuorrc.json" trigger="loop" colors="primary:#121331, secondary:#08a88a" style={{ width: "120px", height: "120px" }}></lord-icon> */}

          {modalDatas.length ? (
            <div className="mt-4">
              <QRCodeSVG value={modalDatas[0].uid} />
              <h5 className="modal-title m-2" id="staticBackdropLabel">
                {modalDatas[0].name}
              </h5>
              <hr></hr>
              <p className="text-muted mb-4">
                <br />
                {/* 주의사항<br></br> */}
                <div
                  style={{ textAlign: "left", fontSize: "14px" }}
                  dangerouslySetInnerHTML={{
                    __html: modalDatas[0].precautions,
                  }}
                />
              </p>
              <div className="hstack gap-2 justify-content-center">
                <Button
                  style={{
                    fontFamily: "Gmarket-M",
                    backgroundColor: "#ff5a5f",
                    borderColor: "#ff5a5f",
                    color: "#fff",
                  }}
                  to="#"
                  className="btn btn-success"
                  onClick={() => {
                    const confirmReserv =
                      window.confirm("정말 사용하시겠습니까?");
                    if (confirmReserv) {
                      if (modalDatas.length) {
                        changeUserVoucherState(modalDatas[0].id);
                        setModalBackdrop(false);
                      }
                    }
                  }}
                  // onClick={() => {
                  //   setModalBackdrop(false);
                  //   setmodal_alert(true);
                  // }}
                >
                  직원확$##########인
                </Button>
              </div>
            </div>
          ) : null}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal_alert}
        toggle={() => {
          tog_toggle_alert();
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_alert(false);
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody className="text-center p-5">
          {/* <lord-icon src="https://cdn.lordicon.com/lupuorrc.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}></lord-icon> */}

          <div className="mt-0">
            <h5
              className="modal-title mb-1"
              id="staticBackdropLabel"
              style={{ fontFamily: "Gmarket-M" }}
            >
              교환권 사용
            </h5>
            <p className="text-muted mb-4">정말 사용하시겠습니까?</p>
            <div className="hstack gap-2 justify-content-center">
              <Button
                style={{
                  fontFamily: "Gmarket-M",
                  backgroundColor: "#ff5a5f",
                  borderColor: "#ff5a5f",
                  color: "#fff",
                }}
                to="#"
                className="btn btn-success"
                onClick={() => {
                  if (modalDatas.length)
                    changeUserVoucherState(modalDatas[0].id);
                  setmodal_alert(false);
                }}
              >
                사용하기
              </Button>
              {/* <Button
                style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
                to="#"
                className="btn fw-medium"
                onClick={() => {
                  setModalDatas([]);
                  setmodal_alert(false);
                }}
              >
                사용 안함
              </Button> */}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <UserVoucherGiftModal
        setVisible={setGiftModal}
        visible={giftModal}
        infos={modalDatas}
        setInfos={setModalDatas}
        needRefresh={setdata}
      />
    </React.Fragment>
  );
};
export default VoucherSendGift;
