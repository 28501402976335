export {};
  import { AxiosResponse } from "axios";
import { API, BACKEND_HOST } from ".";
import { Page } from "./type";
/*
get: 단순 데이터 조회 (GET 요청).
fetch: 데이터 조회 + 추가 로직 가능성 (GET 요청 포함).
post: 데이터 생성 작업 (POST 요청).
patch: 데이터 수정 작업 (PATCH 요청).
delete: 데이터 삭제 작업 (DELETE 요청).
*/
//:::::: KaKaoAPI

//:::::: CouponList
export const getCouponBook = async () => {
  const response = await API.get(`/coupon/book/`, {});
  console.log(response);

  return response;
};

export const postCouponDownById = async (couponId: number) => {
  const response = await API.post(`/coupon/book/download/${couponId}/`, {});

  return response;
};

//:::::: ShoppingPurchaseList
export const getShopData = async () => {
  const response = await API.get(`/shop/item/`, {});

  return response;
};

export const postShopOrderCancelById = async (id: number) => {
  const response = await API.post(`${BACKEND_HOST}/shop/order-cancel/`, {
    order_id: id,
    // partial_cancels: [{ id: itemId }],
    cancel_reason: "사용자 취소",
  });

  return response;
};

//:::::: TicketPurchaseList

export const getTicektListByPage = async (pageData: Page) => {
  const response = await API.get(
    `/ticket/user-ticket-app/?page=${pageData.page}${pageData.queryString}`
  );
  return response;
};

export const postTicketUseById = async (id: number) => {
  const response = await API.post(`/ticket/use-ticket/`, { id: id });
  return response;
};

//:::::: UserVoucher
export const postValidateUserVoucher = async () => {
  const response = await API.post(
    `${BACKEND_HOST}/voucher/check-user-vouchers/`
  );
  return response;
};

export const postUserVoucherStateById = async (itemId: number) => {
  const response = await API.post(`/voucher/process/${itemId}/`, null, {});
  return response;
};
//:::::: CouponGift

export const getCouponDataByPageParam = async (pageParam: number) => {
  const response = API.get(
    `${BACKEND_HOST}/coupon/user/?page=${pageParam}&gifted=true`
  );

  return response;
};

export const putReceptCouponById = async (couponId: number) => {
  const response = await API.put(
    `/coupon/gift/reception/${couponId}/`,
    null,
    {}
  );

  return response;
};

//:::::: CouponSendGift
export const getCouponGiftList = async (pageParam: number) => {
  const response = await API.get(`/coupon/user/?page=${pageParam}&gifted=true`);

  return response;
};

export const putCouponSendCancelById = async (couponId: number) => {
  const response = await API.put(`/coupon/gift/cancel/${couponId}/`, null, {});
  return response;
};

//:::::: ReservationGift

export const getReservationGiftByPhoneNum = async (phoneNum: number) => {
  const response = await API.get(
    `/package/reservation-gift/?recipient=${phoneNum}`
  );

  return response;
};

export const putReceiveReservationById = async (giftId: number) => {
  const response = await API.put(
    `/package/receive-reservation-gift/${giftId}/`,
    null
  );
  return response;
};

//:::::: ReservationSendGift

export const getReservationSendGiftByPage = async (pageData: Page) => {
  const response = await API.get(
    `/package/user-order-app/?page=${pageData.page}${pageData.queryString}`,
    {}
  );
  return response;
};

export const deleteReservationGiftById = async (reservId: number) => {
  const response = await API.delete(
    `/package/cancel-reservation-gift/${reservId}/`
  );
  return response;
};

//:::::: TicketGift

export const putReceiveTicketGiftById = async (giftId: number): Promise<AxiosResponse<any, any>> => {
  const response = await API.put(
    `/ticket/receive-ticket-gift/${giftId}/`,
    null
  );
  return response;
};

export const getTicketGiftByRecipientPhone = async (phoneNum: number) => {
  const response = await API.get(`/ticket/ticket-gift/?recipient=${phoneNum}`);
  return response;
};

//:::::: TicketSendGift
export const getTicketSendDataByPage = async (pageData: Page) => {
  const response = await API.get(
    `/ticket/user-ticket-app/?page=${pageData.page}${pageData.queryString}`
  );
  return response;
};

export const deleteTicketGiftById = async (ticketId: number) => {
  const response = await API.delete(
    `/ticket/cancel-ticket-gift/${ticketId}/`,
    {}
  );

  return response;
};

//:::::: VoucherSendGift

export const getVoucherSendData = async () => {
  const response = await API.get(`/voucher/user/`, {});
  return response;
};

export const postValidateVoucher = async () => {
  const response = await API.post(`/voucher/check-user-vouchers/`, null);
  return response;
};

export const postVoucherStateById = async (itemId: number) => {
  const response = await API.post(`voucher/process/${itemId}/`, null, {});

  return response;
};

export const deleteCancelGiftById = async (itemId: number) => {
  const response = await API.delete(
    `/voucher/cancel-user-voucher-gift/${itemId}/`
  );
  return response;
};

//:::::: VoucherGift

export const getUserDataById = async (userId: number) => {
  const data = await API.get(`/accounts/users/${userId}/`, {});
  return data;
};

export const getVoucherResponseByNum = async (phoneNum: string) => {
  const data = await API.get(
    `/voucher/user-voucher-gift/?recipient=${phoneNum}`
  );
  return data;
};

export const patchReceiveVoucherGiftById = async (giftId: number) => {
  const data = await API.put(
    `/voucher/receive-user-voucher-gift/${giftId}/`,
    null
  );
  return data;
};

//:::::: UserCouponList

export const getUserCouponListByPage = async (pageParam: number) => {
  const response = await API.get(
    `/coupon/user/?page=${pageParam}&gifted=true`,
    {}
  );
  const data = response.data;
  //console.log("orderdata", data);
  return { ...data, results: data.results };
};

export const patchCancelCouponGiftById = async (couponId: number) => {
  try {
    const response = await API.patch(
      `/coupon/gift/cancel/${couponId}/`,
      null,
      {}
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
    // 예외 처리를 원하는 방식으로 수행
    throw error;
  }
};
