import React, { useCallback, useEffect } from "react";
import { Modal, Button, Alert } from "reactstrap";
import { API, BACKEND_HOST } from "../../api";
import axios from "axios";
import { useInfiniteQuery } from "react-query";
import moment from "moment";
import { getAccessToken } from "../../api/auth";

const CouponReservationSelectModal = (props) => {
  const [holiday, setHoliday] = React.useState([]);
  const togModal = () => {
    props.setVisible(!props.visible);
  };
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const accessToken = getAccessToken();

  useEffect(() => {
    API.get("/package/holiday/2024/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.data)
      .then((_data) => {
        console.log("holiday", _data);
        setHoliday(_data.result);
      })
      .catch((error) => console.log(error));
  }, [accessToken]);

  const fetchData = useCallback(
    async ({ pageParam = 1 }) => {
      const response = await API.get(`/coupon/user/?page=${pageParam}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      //   console.log("orderdata", data);
      return { ...data, results: data.results };
    },
    [accessToken]
  );

  const { status, data, error, isSuccess, isFetching, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch } = useInfiniteQuery(
    ["UserCouponList"],
    async ({ pageParam = 1 }) => {
      // console.log("pageParam", pageParam);
      const data = await fetchData({ pageParam });
      //   console.log("data", data);
      return data;
    },
    {
      // getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.next) {
          const match = lastPage.next.match(/page=(\d+)/);
          if (match) {
            return match[1];
          }
        }
        return undefined;
      },
      // getPreviousPageParam: (firstPage) => undefined,
    }
  );

  // useEffect(() => {
  //   console.log("coupon", props.tickets);
  // }, [props.tickets]);

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={props.visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3 pt-4" style={{
          maxHeight: "70vh",
          height: "70vh",
          overflowY: "auto",
        }}>

          <ul className="list-group">
            {data &&
              isSuccess &&
              data.pages.map((page, i) =>
                page.results
                  .filter((coupons, j) => coupons.coupon.bound_type === "reservation" && coupons.state === "사용안함")
                  .map((coupon, j) => {
                    const is_expired = new Date(coupon.coupon.expire_date) < (new Date()).setHours(0,0,0,0);
                    const is_valid_package = coupon.coupon.packages.find((seat) => seat === props.seats.product_id);
                    const is_max_purchase = coupon.coupon.min_purchase <= props.seats.price;
                    const is_vaild_discount = coupon.coupon.discount_type === "price" ? props.seats.price : props.seats.price * (100 - coupon.coupon.discount_rate / 100) >= 0;

                    // 현재 날짜의 요일을 가져오는 함수
                    const getTodayDay = () => {
                      const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
                      const today = new Date();
                      return days[today.getDay()];
                    };

                    const isTodayDayTrue = () => {
                      const todayDay = getTodayDay();
                      return coupon.coupon[`use_${todayDay}`] === true;
                    };

                    const isTodayInReserveRange = (startDate, endDate, reserveDate) => {
                      const today = new Date(reserveDate);
                      const start = new Date(startDate);
                      const end = new Date(endDate);
                      return today >= start && today <= end;
                    };

                    const is_holiday =
                      coupon.coupon.use_holiday === false
                        ? holiday.length > 0 && holiday.find((item) => moment(item).format("YYYY-MM-DD") === moment(props.seats.reserve_date).format("YYYY-MM-DD"))
                          ? false
                          : true
                        : true;

                    const isSelectedCoupon = props.selectCoupon.find(
                      (coupons) => coupons.product_id === props.seats.product_id && coupons.spot_id !== props.seats.spot_id && coupons.coupon_id === coupon.id
                    );

                    // console.log("isSelectedCoupon", isSelectedCoupon);
                    // console.log("coupon", coupon);
                    const disabled =
                      is_expired ||
                      !is_valid_package ||
                      !is_max_purchase ||
                      !is_vaild_discount ||
                      !isTodayDayTrue() ||
                      !isTodayInReserveRange(coupon.coupon.use_start_date, coupon.coupon.use_end_date, props.seats.reserve_date) ||
                      !is_holiday ||
                      isSelectedCoupon;
                    return (
                      <li key={j} className="list-group-item" style={{ opacity: disabled ? 0.5 : 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                          <p style={{ fontSize: "15px", fontFamily: "Gmarket-M", margin: 0, marginBottom: "5px", maxWidth: "95%" }}>{coupon.coupon.title}</p>
                          <p style={{ margin: 0, marginBottom: "5px" }}>만료기한 {coupon.coupon.expire_date}까지</p>
                          <p style={{ margin: 0, marginBottom: "5px" }}>
                            예약기간 {coupon.coupon.use_start_date} ~ {coupon.coupon.use_end_date}
                          </p>
                          <p style={{ fontSize: "15px", fontFamily: "Gmarket-M", color: "#ff5a5f", margin: 0, marginBottom: "5px" }}>
                            {coupon.coupon.discount_type === "price" ? coupon.coupon.discount_amount.toLocaleString() + "원 할인" : coupon.coupon.discount_rate + "% 할인"}
                          </p>
                          <p style={{ margin: 0 }}>구매금액 {coupon.coupon.min_purchase.toLocaleString()}원 이상 사용가능</p>
                          {coupon.coupon.discount_type === "percentage" ? <p>최대 {coupon.coupon.max_discount.toLocaleString()}원까지 할인</p> : null}
                        </div>

                        {disabled ? (
                          <p>사용할 수 없는 쿠폰입니다.</p>
                        ) : (
                          <input
                            type="radio"
                            name="coupon"
                            value={coupon.id}
                            disabled={disabled}
                            checked={
                              props.selectCoupon.length > 0
                                ? props.selectCoupon.find((coupons) => coupons.coupon_id === coupon.id && coupons.product_id === props.seats.product_id && coupons.spot_id === props.seats.spot_id)
                                : false
                            }
                            onChange={() => {
                              props.toggleReservationDiscountCouponSelection(
                                props.seats.product_id,
                                coupon.id,
                                props.seats.spot_id,
                                coupon.coupon.discount_amount,
                                coupon.coupon.discount_type,
                                coupon.coupon.min_purchase,
                                coupon.coupon.discount_rate,
                                coupon.coupon.max_discount
                              );
                            }}
                          />
                        )}
                      </li>
                    );
                  })
              )}
            
            {isFetching && <div>Loading...</div>}
            {isFetchingNextPage && <div>Loading more...</div>}
            {isSuccess && !data.pages[0].results.length && <Alert color="info">보유하신 쿠폰이 없습니다.</Alert>}
            {hasNextPage && (
              <Button
                color="light"
                onClick={() => {
                  fetchNextPage();
                }}
              >
                다음 불러오기
              </Button>
            )}
          </ul>
        </div>
        <div className="d-flex justify-content-center mx-1 mb-4 pb-2">
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            onClick={() => {
              const updatedSelectCoupon = props.selectCoupon.filter((coupon) => coupon.product_id === props.seats.product_id && coupon.spot_id !== props.seats.spot_id);
              // console.log("updatedSelectCoupon", props.seats);
              props.setSelectCoupon(updatedSelectCoupon);
              props.setVisible(!props.visible);
            }}
          >
            취소
          </Button>
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            적용
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default CouponReservationSelectModal;
